.gradient{
    background: linear-gradient(-45deg, #EE7752, #E73C7E, #23A6D5, #23D5AB);
	background-size: 400% 400%;
    animation: gradient 3s ease; 
    animation-iteration-count: infinite;
    fill: linear-gradient(-45deg, #EE7752, #E73C7E, #23A6D5, #23D5AB);
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}