.modal {
  -ms-overflow-style: none;
}
.modal ::-webkit-scrollbar {
  display: none;
}

.frame {
  -ms-zoom: 2;
  -moz-transform: scale(0.9);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.9);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.9);
  -webkit-transform-origin: 0 0;
}

/* .colorBox::before {
  border: 2px solid red;
  border-radius: 5px;
} */

.colorBox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  width: 22px;
  height: 27px;
}

.colorBox::-webkit-color-swatch {
  border-radius: 2px;
  border: none;
}
.colorBox::-moz-color-swatch {
  border-radius: 2px;
  border: none;
}
