.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* TODO: 자체 컴포넌트로 스타일 대체 */
.ant-ribbon-wrapper {
  height: 100%;
}

.ant-menu-item-selected {
  background-color: #fff !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.unsplash .ant-dropdown-menu .ant-dropdown-menu-item:hover {
  background-color: #fff;
  cursor: default;
}

.design-modal .ant-modal-content {
  padding: 0px;
  /* padding-bottom: 20px; */
  /* border-bottom-left-radius: 20px; */
  /* border-bottom-right-radius: 20px; */
}

.design-modal .ant-modal-header {
  margin-bottom: 0px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  opacity: 1 !important;
}

._Point_gkfp3_144 {
  outline: none;
  pointer-events: none;
}
._Point_gkfp3_144:focus,
._Point_gkfp3_144:focus-visible {
  outline: none !important;
}

._VisuallyHidden_gkfp3_152 {
  position: absolute !important;
  top: 0;
  clip: rect(1px, 1px, 1px, 1px) !important;
  overflow: hidden !important;
  height: 1px !important;
  width: 1px !important;
  padding: 0 !important;
  border: 0 !important;
  opacity: 0;
}

._Crosshair_1t1jt_1 {
  pointer-events: none;
}

._ChartContainer_1mpes_144 {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'San Francisco', 'Segoe UI',
    Roboto, 'Helvetica Neue', sans-serif;
  font-size: 12px;
}
._ChartContainer_1mpes_144 *:focus,
._ChartContainer_1mpes_144 *:focus-visible {
  outline: 2px solid #4b92e5;
  outline-offset: 2px;
}

._Shimmer_hqp2w_1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(5, 10);
  -webkit-animation-name: _shimmer_hqp2w_1;
  animation-name: _shimmer_hqp2w_1;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  /* stylelint-disable */
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  /* stylelint-enable */
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background-size: calc(var(--shimmerWidth) * 1) calc(var(--shimmerHeight) * 1);
  background-position-y: 0;
  pointer-events: none;
}

@-webkit-keyframes _shimmer_hqp2w_1 {
  0% {
    background-position-x: calc(var(--shimmerWidth) * 1 * -1);
  }
  50% {
    background-position-x: calc(var(--shimmerWidth) * 1 * 2);
  }
  51% {
    background-position-x: calc(var(--shimmerWidth) * 1 * -1);
  }
  100% {
    background-position-x: calc(var(--shimmerWidth) * 1 * 2);
  }
}

@keyframes _shimmer_hqp2w_1 {
  0% {
    background-position-x: calc(var(--shimmerWidth) * 1 * -1);
  }
  50% {
    background-position-x: calc(var(--shimmerWidth) * 1 * 2);
  }
  51% {
    background-position-x: calc(var(--shimmerWidth) * 1 * -1);
  }
  100% {
    background-position-x: calc(var(--shimmerWidth) * 1 * 2);
  }
}

._Arc_12guq_144 {
  outline: none;
}
._Arc_12guq_144:focus,
._Arc_12guq_144:focus-visible {
  outline: none !important;
}

._Donut_e6ecz_144 {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: visible;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  align-items: center;
}
._Donut_e6ecz_144 svg {
  width: 100%;
  height: 100%;
}

._DonutWrapper_e6ecz_158 {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

._ContentWrapper_e6ecz_166 {
  position: absolute;
  justify-content: center;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  font-size: 20px;
  pointer-events: none;
  width: 100%;
  height: 100%;
}
._ContentWrapper_e6ecz_166 span {
  max-width: 40%;
  text-align: center;
  color: white;
  font-size: 12px;
}

._DonutChart_e6ecz_184 {
  outline: none;
  overflow: visible;
}
._DonutChart_e6ecz_184:focus,
._DonutChart_e6ecz_184:focus-visible {
  outline: none !important;
}

._Label_1vvi2_144 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

._Bar_1hwkw_144 {
  outline: none;
  transition: fill 300 ease;
}
._Bar_1hwkw_144:focus,
._Bar_1hwkw_144:focus-visible {
  outline: none !important;
}

@media print {
  ._Group_1hwkw_153 {
    transform: none !important;
  }
}

._Bar_h2fc5_144 {
  outline: none;
}
._Bar_h2fc5_144:focus,
._Bar_h2fc5_144:focus-visible {
  outline: none !important;
}

._Group_kzepq_144 {
  outline: none;
}
._Group_kzepq_144:focus,
._Group_kzepq_144:focus-visible {
  outline: none !important;
}

._SimpleBarSkeleton_1nscx_1 {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
}

._Item_1nscx_8 {
  display: grid;
  grid-template-rows: 16px 40px;
  grid-template-columns: 20% auto;
  gap: 20px;
}
._Item_1nscx_8 > *:last-child {
  grid-column: span 2;
}

._SimpleNormalizedSkeleton_sdj88_1 > span {
  width: 100%;
  height: 16px;
  display: block;
}

._Legend_sdj88_7 {
  height: 100px;
  display: flex;
  gap: 5%;
  max-width: 60%;
}

._LegendItem_sdj88_14 {
  width: 100%;
  display: block;
  display: grid;
  grid-template-columns: 16px 1fr;
  grid-template-rows: 16px;
  grid-gap: 10px;
}
._LegendItem_sdj88_14 > *:first-child {
  width: 16px;
  height: 16px;
}
._LegendItem_sdj88_14 > *:nth-child(2) {
  height: 16px;
}
._LegendItem_sdj88_14 > *:nth-child(3) {
  grid-column: 2;
  height: 16px;
  width: 80%;
}

._Container_1ymq9_1 {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

._ChartDimensions_qgx6z_1 {
  height: 100%;
  width: 100%;
}

._Chart_qgx6z_1 {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
._Chart_qgx6z_1 svg {
  overflow: visible;
  max-width: 100%;
}

._VisuallyHidden_1laex_144 {
  position: absolute !important;
  top: 0;
  clip: rect(1px, 1px, 1px, 1px) !important;
  overflow: hidden !important;
  height: 1px !important;
  width: 1px !important;
  padding: 0 !important;
  border: 0 !important;
}

._VisuallyHidden_12uto_144 {
  position: absolute !important;
  top: 0;
  clip: rect(1px, 1px, 1px, 1px) !important;
  overflow: hidden !important;
  height: 1px !important;
  width: 1px !important;
  padding: 0 !important;
  border: 0 !important;
  opacity: 0;
}

._ComparisonLine_12uto_156 {
  stroke-linecap: round;
}

._SkipLink_1jwcn_1 {
  position: absolute;
  top: 1px;
  left: 1px;
  background: white;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  border-radius: 0.4rem;
  color: #212b36;
  border: 1px solid #babfc3;
  padding: 8px 16px;
  opacity: 0;
  pointer-events: none;
  text-decoration: none;
}
._SkipLink_1jwcn_1:focus {
  pointer-events: all;
  opacity: 1;
}

._Line_y7hzj_1 {
  pointer-events: none;
}

._Blur_m9tly_1 {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

._Button_m9tly_9 {
  border: 0;
  background: transparent;
  height: 100%;
  width: 100%;
  font-size: 0;
  display: block;
}

._Container_803jc_1 {
  pointer-events: auto;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  padding: 8px 12px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}

._Title_803jc_9 {
  font-weight: 500;
  line-height: 20px;
  font-size: 13px;
  margin: 0 0 4px;
}

._Content_803jc_16 {
  font-size: 12px;
  line-height: 16px;
  margin: 0;
}

._Link_803jc_22 {
  margin-left: 4px;
}

._Button_pb42n_1 {
  border: 0;
  background: transparent;
  height: 100%;
  width: 100%;
  font-size: 0;
  display: block;
}

._Group_kzepq_144 {
  outline: none;
}
._Group_kzepq_144:focus,
._Group_kzepq_144:focus-visible {
  outline: none !important;
}

._Container_11i26_1 {
  display: flex;
}

._ColorPreview_t4h1x_144 {
  border-radius: 2px;
  display: block;
  flex: none;
  -webkit-print-color-adjust: exact;
  /* stylelint-disable-line property-no-vendor-prefix */
  print-color-adjust: exact;
  /* stylelint-disable-line property-no-unknown */
}

._Legend_1vu5z_144 {
  padding: 6px 0;
  outline: none;
  border: none;
  border-radius: 2px;
  display: flex;
}
._Legend_1vu5z_144:focus,
._Legend_1vu5z_144:focus-visible {
  outline: 2px solid #4b92e5;
  outline-offset: 2px;
}

._TextContainer_1vu5z_156 {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 3px;
  line-height: 16px;
  margin: -2px 0;
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, 'San Francisco', 'Segoe UI',
    Roboto, 'Helvetica Neue', sans-serif;
}

._IconContainer_1vu5z_167 {
  display: flex;
  align-items: center;
  justify-items: center;
}

._Container_3h6ct_1 {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

._Container_9yqry_1 {
  position: absolute;
  pointer-events: none;
  max-width: 70%;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2), 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
}

._VisuallyHidden_at2zh_144 {
  position: absolute !important;
  top: 0;
  clip: rect(1px, 1px, 1px, 1px) !important;
  overflow: hidden !important;
  height: 1px !important;
  width: 1px !important;
  padding: 0 !important;
  border: 0 !important;
  opacity: 0;
}

._Group_1lyoh_144 * {
  outline: none;
  pointer-events: none;
}
._Group_1lyoh_144 *:focus,
._Group_1lyoh_144 *:focus-visible {
  outline: none !important;
}

._PositiveIcon_jzrec_144,
._NegativeIcon_jzrec_145,
._NeutralIcon_jzrec_146 {
  display: flex;
  align-items: center;
}

._PositiveIcon_jzrec_144 svg {
  margin-right: 4px;
}

._NegativeIcon_jzrec_145 svg {
  margin-right: 4px;
}

._VisuallyHidden_jzrec_159 {
  position: absolute !important;
  top: 0;
  clip: rect(1px, 1px, 1px, 1px) !important;
  overflow: hidden !important;
  height: 1px !important;
  width: 1px !important;
  padding: 0 !important;
  border: 0 !important;
  opacity: 0;
}

._VisuallyHidden_at2zh_144 {
  position: absolute !important;
  top: 0;
  clip: rect(1px, 1px, 1px, 1px) !important;
  overflow: hidden !important;
  height: 1px !important;
  width: 1px !important;
  padding: 0 !important;
  border: 0 !important;
  opacity: 0;
}

._VisuallyHidden_at2zh_144 {
  position: absolute !important;
  top: 0;
  clip: rect(1px, 1px, 1px, 1px) !important;
  overflow: hidden !important;
  height: 1px !important;
  width: 1px !important;
  padding: 0 !important;
  border: 0 !important;
  opacity: 0;
}

._Container_1vatj_1 {
  display: flex;
  align-items: baseline;
}

._ContainerDefaultLabel_1vatj_6 {
  margin-bottom: 32px;
}

._ContaineBottomLabel_1vatj_10 {
  margin-top: 20px;
}

._LabelColor_1vatj_14 {
  margin: 1px;
  border-radius: 3px;
  height: 10px;
  width: 10px;
}

._Label_1vatj_14 {
  flex: 1 1;
  margin: 0 8px;
  word-break: break-word;
}

._ValueContainer_1vatj_27 {
  margin-top: 4px;
  display: flex;
}

._Value_1vatj_27 {
  margin-right: 8px;
}

._Segment_7hgfv_144 {
  outline: none;
  margin: 1px 2px 2px 1px;
}
._Segment_7hgfv_144:focus,
._Segment_7hgfv_144:focus-visible {
  outline: none !important;
}
._Segment_7hgfv_144:last-of-type {
  margin: 1px;
}

._horizontal-RoundedCorners_7hgfv_155:first-of-type {
  border-radius: 2px 0 0 2px;
}
._horizontal-RoundedCorners_7hgfv_155:last-of-type {
  border-radius: 0 2px 2px 0;
}

._vertical-RoundedCorners_7hgfv_162:first-of-type {
  border-radius: 0 0 2px 2px;
}
._vertical-RoundedCorners_7hgfv_162:last-of-type {
  border-radius: 2px 2px 0 0;
}

._RoundedCorners_7hgfv_169 {
  border-radius: 2px;
}

._horizontal-small_7hgfv_173 {
  height: 16px;
}

._horizontal-medium_7hgfv_177 {
  height: 36px;
}

._horizontal-large_7hgfv_181 {
  height: 56px;
}

._vertical-small_7hgfv_185 {
  width: 16px;
}

._vertical-medium_7hgfv_189 {
  width: 36px;
}

._vertical-large_7hgfv_193 {
  width: 56px;
}

._Container_12ffk_144 {
  font-family: -apple-system, BlinkMacSystemFont, 'San Francisco', 'Segoe UI',
    Roboto, 'Helvetica Neue', sans-serif;
  font-size: 14px;
  display: flex;
  box-sizing: border-box;
  -webkit-print-color-adjust: exact;
  /* stylelint-disable-line property-no-vendor-prefix */
  print-color-adjust: exact;
  /* stylelint-disable-line property-no-unknown */
}

._VerticalContainer_12ffk_155 {
  height: 100%;
}

._VerticalContainerRightLabel_12ffk_159 {
  flex-direction: row-reverse;
  justify-content: flex-end;
}

._HorizontalContainer_12ffk_164 {
  flex-direction: column;
  justify-content: normal;
}

._HorizontalContainerBottomLabel_12ffk_169 {
  flex-direction: column-reverse;
}

._BarContainer_12ffk_173 {
  display: flex;
}

._VerticalBarContainer_12ffk_177 {
  flex-direction: column-reverse;
}

._HorizontalBarContainer_12ffk_181 {
  flex-direction: row;
}

._VerticalLabelContainer_12ffk_185 {
  margin: 0 20px;
  padding: 0;
}

._HorizontalLabelContainer_12ffk_190 {
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, -webkit-max-content));
  grid-template-columns: repeat(auto-fit, minmax(150px, max-content));
}

._LabelContainerEndJustify_12ffk_196 {
  justify-content: end;
  justify-items: end;
}

._ChartContainer_160ws_1 {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

._SVG_160ws_11 {
  overflow: visible;
  max-width: 100%;
}

._Group_kzepq_144 {
  outline: none;
}
._Group_kzepq_144:focus,
._Group_kzepq_144:focus-visible {
  outline: none !important;
}

@media print {
  ._Group_oqjz6_2 {
    -webkit-clip-path: none;
    clip-path: none;
  }
}

._Bar_1w2vx_144 {
  outline: none;
  transition: fill 300 ease;
}
._Bar_1w2vx_144:focus,
._Bar_1w2vx_144:focus-visible {
  outline: none !important;
}

._BarGroup_1gnzm_144,
._Bar_1gnzm_144 {
  outline: none;
}
._BarGroup_1gnzm_144:focus,
._BarGroup_1gnzm_144:focus-visible,
._Bar_1gnzm_144:focus,
._Bar_1gnzm_144:focus-visible {
  outline: none !important;
}

._Bar_h2fc5_144 {
  outline: none;
}
._Bar_h2fc5_144:focus,
._Bar_h2fc5_144:focus-visible {
  outline: none !important;
}

._Group_kzepq_144 {
  outline: none;
}
._Group_kzepq_144:focus,
._Group_kzepq_144:focus-visible {
  outline: none !important;
}

._Donut_p25dv_144 {
  position: relative;
  display: flex;
  overflow: visible;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  align-items: center;
}

._DonutWrapper_p25dv_152 {
  position: relative;
  display: flex;
  align-items: center;
}

._ContentWrapper_p25dv_158 {
  position: absolute;
  justify-content: center;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  font-size: 20px;
  pointer-events: none;
  width: 100%;
  height: 100%;
}

._ContentValue_p25dv_170 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  margin: 0;
  font-variant-numeric: tabular-nums;
}

._ComparisonMetric_p25dv_179 {
  font-size: 16px;
}

._DonutChart_p25dv_183 {
  outline: none;
  overflow: visible;
}
._DonutChart_p25dv_183:focus,
._DonutChart_p25dv_183:focus-visible {
  outline: none !important;
}

._Label_17pex_1 {
  text-align: center;
  padding-top: 6px;
}
._Label_17pex_1 span {
  padding: 0 4px;
  border-radius: '4px';
  border-radius: 4px;
}
._Label_17pex_1 span:empty {
  display: none;
}

._Container_43tig_144 {
  padding: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  border-radius: 8px;
  min-width: 180px;
}

._Title_43tig_152 {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 0 0 10px;
}

._AxisTitle_43tig_159 {
  line-height: 16px;
  margin: 0 0 8px;
}

._Series_43tig_164:not(:last-child) {
  margin: 0 0 13px;
}

._Row_43tig_168 {
  display: flex;
  align-items: center;
  height: 12px;
}

._Value_43tig_174 {
  margin-left: auto;
  text-align: right;
  white-space: nowrap;
}

._Truncate_43tig_180 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

._Row_1ynfo_1 {
  line-height: 20px;
  font-size: 14px;
}
._Row_1ynfo_1:not(:last-child) {
  margin: 0 0 10px;
}
